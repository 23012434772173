import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-fixed',
  templateUrl: './menu-fixed.component.html',
  styleUrls: ['./menu-fixed.component.css']
})
export class MenuFixedComponent {

}
