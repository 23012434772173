<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <!-- <a class="navbar-brand" routerLink="">Logo</a> -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav  ms-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">início</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/madrinhas" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">madrinhas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/padrinhos" routerLinkActive="active-link">padrinhos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/lista-casamento" routerLinkActive="active-link">lista de casamento</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/lista-casamento" routerLinkActive="active-link">confirmar presença</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
