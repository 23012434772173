<app-navbar></app-navbar>
<!-- <app-menu-fixed></app-menu-fixed> -->
<div class="lista-casamento">
  <div class="blocoInicial">
      <div class="title">
        <div class="container">
            <div class="row">
                <div class="text-center text-lista-casamento">
                  <h1 class="mobile-text">Lista de Presentes</h1>
                  <img class="florAzulMeio" src="assets\img\florMeioAzul.png" alt="">
                </div>
                <div class="agradecimento">
                  <h5>Criamos esse espaço para colocar listas de presente justa e variada para ajudar aqueles que querem presentear.</h5>
                  <p class="comCarinho">Com carinho, Andresa e Vinícius</p>
                </div>
            </div>
        </div>
      </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="card col-3">
        <a href="https://www.amazon.com.br/hz/wishlist/ls/DA8BW305Y2J5?ref_=wl_share " target="_blank">
          <div class="circle">
            <img class="ltsPresente imgPresente" src="assets/img/home/listaPresente/amazon.png" alt="">
          </div>
          <div class="content">
            <p>Indicado para quem tem a assinatura Amazon Prime e, portanto, consegue enviar o presente com frete gratis e sem estresse.</p>
            <a href="https://www.amazon.com.br/hz/wishlist/ls/DA8BW305Y2J5?ref_=wl_share " target="_blank">Dar uma olhadinha</a>
          </div>
        </a>
      </div>

      <div class="card col-3">
        <a href="https://lista.camicado.com.br/be_e_de" target="_blank">
          <div class="circle">
            <img class="ltsPresente imgPresente" src="assets/img/home/listaPresente/camicado.png" alt="">
          </div>
          <div class="content">
            <p>A Camicado tem uma lista com peças lindas e com parcelamento sem juros na maioria das peças.</p>
            <a href="https://lista.camicado.com.br/be_e_de" target="_blank">Dar uma olhadinha</a>
          </div>
        </a>
      </div>

      <div class="card col-3">
        <a href="https://www.querodecasamento.com.br/lista-de-casamento/andresa-vinicius" target="_blank">
          <div class="circle">
            <img class="ltsPresente imgPresente" src="assets/img/home/listaPresente/magalu.png" alt="">
          </div>
          <div class="content">
            <p>Uma loja com muitas opções de presentes e também com entrega facil, rápida e parcelamento sem juros.</p>
            <a href="https://www.querodecasamento.com.br/lista-de-casamento/andresa-vinicius" target="_blank">Dar uma olhadinha</a>
          </div>
        </a>
      </div>

      <div class="card col-3">
        <a href="https://www.precolandia.com.br/gift-list-details/view/casamento-andresa-e-vinicius-09-11-2024" target="_blank">
          <div class="circle">
            <img class="ltsPresente imgPresente" src="assets/img/home/listaPresente/precolandia.png" alt="">
          </div>
          <div class="content">
            <p>Criamos uma lista com variedade de presentes e valores que podem ser comprados ou, até mesmo, ajudar na ideia de presentes.</p>
            <a href="https://www.precolandia.com.br/gift-list-details/view/casamento-andresa-e-vinicius-09-11-2024" target="_blank">Dar uma olhadinha</a>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

