import { Component } from '@angular/core';

@Component({
  selector: 'app-lista-casamento',
  templateUrl: './lista-casamento.component.html',
  styleUrls: ['./lista-casamento.component.css']
})
export class ListaCasamentoComponent {

}
