<div class="container-fluid footer fixar-rodape">
  <div class="row">
    <div class="col-lg-6">
      <h1>Desenvolvido pela noiva</h1>
    </div>

    <!-- <div class="col-lg-4">
      <a routerLink="">Home</a>
      <a routerLink="menu">Menu</a>
      <a routerLink="about">Sobre</a>
      <a routerLink="contact">Contato</a>
    </div> -->

    <div class="container menuHome hidden">
      <div class="row">
        <div class="col-3">
          <a href="#" class="linkMenu" data-bs-toggle="modal" data-bs-target="#igreja">
            <img class="imgMenuHome imgMenu" src="assets\img\home\igreja.png" alt="">
            <p class="card-text textHomeP">Igreja</p>
          </a>
        </div>
        <div class="col-3">
          <a href="#" class="linkMenu">
            <img class="imgMenuHome imgMenu" src="assets\img\home\festa.png" alt="">
            <p class="card-text textHomeP">Festa</p>
          </a>
        </div>
        <div class="col-3">
          <a href="#" class="linkMenu">
            <img class="imgMenuHome imgMenu" src="assets\img\home\presente.png" alt="">
            <p class="card-text textHomeP">Lista de Presentes</p>
          </a>
        </div>
        <div class="col-3">
          <a href="#" class="linkMenu">
            <img class="florMeio imgMenu" src="assets\img\home\padrinhos.png" alt="">
            <p class="card-text textHomeP">Padrinhos</p>
          </a>
        </div>
      </div>
    </div>

    <div class="col-lg-2">
      <a href="" target="_blank"><i class="bi bi-instagram"></i></a>
      <a href="" target="_blank"><i class="bi bi-twitter"></i></a>
    </div>
  </div>
</div>
