<div class="fixed-menu">
    <a routerLink="" class="link home">
      <i class="fa fa-home" aria-hidden="true"></i>
      <span>início</span>
    </a>
    <a routerLink="/madrinhas" class="link icon">
      <!-- <img class="imgPadrinhos" src="assets\img\home\vestido.png" alt=""> -->
      <i class="fa fa-user-o" aria-hidden="true"></i>
      <span>madrinha</span>
    </a>
    <a routerLink="/padrinhos" class="link icon">
      <!-- <img class="imgPadrinhos" src="assets\img\home\terno.png" alt=""> -->
      <i class="fa fa-user" aria-hidden="true"></i>
      <span>padrinho</span>
    </a>
    <a routerLink="/lista-casamento" class="link icon">
      <i class="fa fa-gift" aria-hidden="true"></i>
      <span>lista de casamento</span>
    </a>
    <a routerLink="/lista-casamento" class="link icon">
      <i class="fa fa-file-text-o" aria-hidden="true"></i>
      <span>confirmar presença</span>
    </a>
</div>
