<app-navbar></app-navbar>
<!-- <app-menu-fixed></app-menu-fixed> -->
<div class="padrinho">
  <div class="blocoInicial">
      <div class="title">
        <div class="container">
            <div class="row">
                <div class="text-center text-padrinhos">
                  <h1 class="mobile-text">Padrinhos</h1>
                  <img class="florAzulMeio" src="assets\img\florMeioAzul.png" alt="">
                </div>
                <div class="agradecimento">
                  <h5>muito obrigada por aceitar a missão de ser a nosso padrinho e compartilhar esse momento tão lindo das nossas vidas!</h5>
                  <p class="comCarinho">com carinho, Andresa e Vinícius </p>
                </div>
            </div>
        </div>
      </div>
  </div>
  <div class="meioCores">
    <div class="container">
      <div>
        <h4 class="reservei text-center">reservei esse espaço para te ajudar na escolha das cores seu terno.</h4>
        <h3 class="referenciaTexto text-center">abaixo está a referência da cor do terno dos padrinhos</h3>
      </div>
      <div class="coracao texto">
        <h2 class="text-center cinza">cinza claro</h2>
        <div class="row text-center">
          <div class="col-4">
            <img class="imgCoracao" src="assets\img\home\coracao\coracao1.png" alt="">
          </div>
          <div class="col-4">
            <img class="imgCoracao" src="assets\img\home\coracao\coracao2.png" alt="">
          </div>
          <div class="col-4">
            <img class="imgCoracao" src="assets\img\home\coracao\coracao3.png" alt="">
         </div>
        </div>
          <!-- <div class="row text-center">
            <div class="modal" *ngIf="selectedImage">
              <span class="close" (click)="closeModal()">&times;</span>
              <img [src]="selectedImage" class="modal-content" />
            </div>
            <div class="gallery col-12">
              <div *ngFor="let image of images" class="image-wrapper">
                <img [src]="image" (click)="openModal(image)" />
              </div>
            </div>
          </div> -->
      </div>
    </div>
  </div>
  <div class="blocoFinal">
    <div class="container">
        <div class="row text-center final">
            <h3 class="Terno">ternos para se inspirar</h3>
            <h5 class="voceLinda">para arrasar junto com a madrinha</h5>
            <app-opt-image-padrinhos></app-opt-image-padrinhos>
        </div>
    </div>
  </div>
</div>

